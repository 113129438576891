/*
SCREEN BREAKPOINTS
*/
$d1440-width: 1440px;
$desktop-width: 1024px;
$tablet-width: 768px;
$small-tablet-width: 580px;
$mobile-large: 425px;

/*
BREAKPOINTS
*/

@mixin desktoplarge {
  @media (max-width: #{$d1440-width}) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: #{$d1440-width}) {
    @content;
  }
}

@mixin smalldesktop {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin smalltablet {
  @media (max-width: #{$small-tablet-width}) {
    @content;
  }
}

@mixin mobilelarge {
  @media (max-width: #{$mobile-large}) {
    @content;
  }
}

//////OLD /////
// core colors
$core-purple: #5b127f;
$core-green: #9dcb00;

// secondary colors
$dark-purple: #2d093f;
$bright-pink: #a4266d;
$background-grey-pink: #faf2f6;

// greys

$very-light-grey: #f1f1f1;

$very-dark-grey: #333333;

// other colors
$very-light-blue: #cce0ff;
$pale-blue-grey: #ecf5ff;
$pale-pink: #ffe5e3;
