.fake__users__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 24px 0px;
}

.fake__users__container .fake__user__item {
  height: 52px;
  width: 52px;
  border-radius: 50%;
  border: 3px solid white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: -12px;
}
