@use "_variables" as v;
@use '_ListItem';
@use '_Panel';
@use '../components/fakeUsers/FakeUsers';
@use '../components/searchPopUp/SearchPopUp';

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap&amp;subset=latin-ext");

:root {
  --text: #586174;
  --light-grey: #99a2b4;
  --green: #178082;
  --orange: orange;
  --error-red: #ec5858;
  --border-grey: #d4dadf;
  --background: #f8f7f5;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  background-color: var(--background);
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
span,
small,
button,
input,
tr,
th,
textarea,
.text,
div,
label {
  font-family: "Roboto", sans-serif;
  color: var(--text);
  font-weight: 400;
}

a {
  color: #1a222b;
  font-weight: bold;
}

h1 {
  font-size: 2em;
  color: #1a222b;
  letter-spacing: 0.17px;
  line-height: 42px;
  font-weight: 600;
}

h2 {
  font-size: 1.25em;
  color: #1a222b;
  letter-spacing: 0.11px;
  font-weight: 400;
}

h3 {
  color: #2f3437;
  margin-bottom: 32px;
  font-size: 1em;
  font-weight: 500;
}

p {
  font-size: 1em;
  color: #1a222b;
  letter-spacing: 0.11px;
  line-height: 24px;
}

ul {
  list-style: none; /* Remove default bullets */
  list-style-position: outside;
  margin: 16px 0px;
}

ul li {
  margin: 4px 0px;
  font-size: 1em;
  color: #1a222b;
}

ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  font-size: 1.25em;
  color: orange; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.app__container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  height: 100%;
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0px 24px;

  @include v.desktop {
    padding: 0px 128px;
  }

  @include v.smalldesktop {
    padding: 0px 68px;
  }

  @include v.tablet {
    padding: 0px 16px;
    flex-direction: column;
  }
}

.page {
  max-width: 800px;
  margin: auto;
  margin-top: 32px;
  background-color: white;
  padding: 8px 32px;
  border-radius: 6px;
}

.intro {
  margin-top: 64px;
  margin-bottom: 64px;
}

.intro img {
  max-width: 280px;
  height: auto;
  display: block;
  margin: auto;
}

.intro label {
  display: block;
  margin-bottom: 8px;
}

button {
  background-color: orange;
  padding: 8px 16px;
  color: black;
  border: none;
  border-radius: 6px;
  min-height: 40px;
  min-width: 68px;
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
  font-size: 1em;
  height: 48px;
  font-weight: 600;
}

button:hover {
  background-color: rgb(255, 187, 0);
}

button:disabled {
  background-color: rgba(255, 165, 0, 0.2);
  pointer-events: none;
}

.search button {
  width: 100%;
  margin-top: 8px;
}

.search {
  margin-bottom: 64px;
  position: relative;
}

footer {
  margin-top: auto;
  padding: 32px;
  text-align: center;
}

.form {
  margin-bottom: 31px;
}

input {
  display: block;
}

input[type="text"] {
  background: #ffffff;
  border: 1px solid rgba(69, 84, 100, 0.4);
  border-radius: 6px;
  width: 100%;
  height: 48px;
  margin: 8px 0px;
  padding: 16px 8px;
  font-size: 1em;
}

input::placeholder {
  color: transparentize($color: grey, $amount: 0.4);
}

.search h3 {
  text-align: center;
}

.search__show-button {
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  width: 200px;
  text-align: center;
  opacity: 0.8 !important;
}

.search__show-button:hover,
.search__show-button:focus {
  background-color: black;
}

.loading {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: rgba(109, 118, 131, 0.5);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transform: opacity 300ms ease-in-out;
}

.loading--show {
  z-index: 3;
  opacity: 1;
}

.error {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 4;
  background-color: var(--error-red);
  text-align: center;
  transition: transform 300ms ease-in-out;
  transform: translateY(-200%);
}

.error p,
.error a {
  color: white;
}

.error--show {
  transform: translateY(0);
}

/* ///////////////////////////////
///////////Fake Users//////////
/////////////////////////////// */
