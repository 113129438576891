@use "../styles/variables" as v;

.panel {
  max-width: 100%;
  width: 100%;
  background-color: var(--background);
  display: flex;
  align-items: center;
  flex-direction: column;

  .intro {
    margin-top: 32px;
  }

  .panel__container {
    margin-top: 10vh;
    width: 100%;

    .logo {
      height: 86px;
      img {
        max-height: 100%;
        width: auto;
      }
    }
  }

  @include v.tablet {
    padding: 0px 24px;
    max-width: 100%;
    .panel__container {
      margin-top: 24px;
    }
  }

  @include v.mobilelarge {
    padding: 0px;
  }
}

.panel.left {
  .panel__container {
    .intro h1 {
      margin-bottom: 24px;
    }
  }
}

.panel.right {
  .panel__container {
    background-color: white;
    border: 1px solid rgba(69, 84, 100, 0.2);
    padding: 24px;
    border-radius: 6px;
    h1 {
      text-align: center;
    }

    form {
      .input__container {
        width: 100%;
        position: relative;
        svg {
          position: absolute;
          top: 50%;
          left: 22px;
          transform: translate(-50%, -50%);
          color: transparentize($color: grey, $amount: 0.5);
        }

        input {
          padding-left: 38px;
        }
      }
    }
  }

  .recipients {
    max-height: 50vh;
    overflow-y: scroll;
  }
}
