.list-item {
  border-bottom: 1px solid var(--border-grey);
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    flex-basis: 240px;

    li {
      font-weight: 600;
      font-size: 1em;
    }
  }

  button {
    width: fit-content;
    margin-right: 24px;
  }

  p {
    margin: 0;
    font-size: 1em;
  }

  &__button {
    color: black;
    background: none;
    text-decoration: underline;
    padding: 0;
    min-width: auto;
    min-height: auto;
    transition: color 300ms ease-in-out;
    margin-left: 8px;
  }

  &:hover {
    color: orange;
    background: none;
  }

  &__add {
    background-color: #e7e8eb;
    border-radius: 4px;
    width: fit-content;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparentize($color: #750feb, $amount: 0.85);
    font-size: 1em;
    transition: all 1s ease;

    span {
      color: #750feb;
    }

    &:hover {
      background-color: transparentize($color: #750feb, $amount: 0.6);
    }
  }
}
