@use "../../styles/variables" as v;

.search__pop__up {
  position: absolute;
  top: 128px;
  left: 50%;
  transform: translate(-50%);
  height: 512px;
  width: 90%;
  background-color: white;
  border-radius: 6px;

  overflow-y: hidden;
  -webkit-box-shadow: 0px 10px 30px -12px rgba(82, 82, 82, 0.2);
  -moz-box-shadow: 0px 10px 30px -12px rgba(82, 82, 82, 0.2);
  box-shadow: 0px 10px 30px -12px rgba(82, 82, 82, 0.2);

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparentize($color: #560bad, $amount: 0);
    padding: 24px;

    h2 {
      font-size: 1.125em;
      font-weight: 400;
      margin-bottom: 0px;
      color: white;
    }

    .add {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        background: transparentize($color: orange, $amount: 0);
        padding: 8px;
        border-radius: 6px;

        svg {
          color: black;
        }
      }
    }
  }

  .close {
    position: relative;
    background-color: transparent;
    border-radius: 50%;
    width: 26px;
    height: 26px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      color: white;
      pointer-events: none;
    }
  }

  .list__item__container {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    padding: 24px;

    .loader__container {
      display: flex;
      align-items: center;

      .loader {
        width: fit-content;
        margin: 0 auto;

        svg {
          fill: orange;
        }
      }
    }

    .list-item {
      flex-basis: 250px;

      &:last-child {
        margin-bottom: 64px;
      }
    }

    button {
      width: fit-content;
    }
  }
  @include v.tablet {
    width: 100%;
  }
}
